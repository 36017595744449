export const state = () => ({
  about: {
    text1: `Partiendo de la pregunta sobre ¿qué es una imagen ausente?, reflexiono sobre cuáles son las
imágenes que están configurando nuestra forma de ver el mundo y como éstas construyen
estereotipos, discursos y realidades.`,
    text2: `La formulación de esta interrogante, en una época hiper saturada de imágenes, invita a
realizar un ejercicio de imaginación, a pensar las imágenes en términos históricos, existenciales
o a partir de lo incierto, desde lo que aún no llegamos a construir, soñar e imaginar.`,
    text3: `La pregunta es un disparador para reflexionar e intercambiar ideas sobre cómo en un régimen
de visibilidad algo puede ser visto. Me interesa la resonancia que genera la pregunta en las
personas, desde la insistencia en la aparición de algo inexistente.`,
    text4: `Fernanda Aramuni`,
  },
  contact: {
    text: `Proyecto participativo, donde
las imágenes recibidas por medio de éste sitio Web y RRSS generan un
archivo vivo que se va transformando.`,
  },
  copyrightDate: new Date().getFullYear(),
})

export const getters = {
  getProjectById: (state) => (id) => {
    return state.projects.find((project) => project.id === id)
  },
}

export const mutations = {
  // @todo
}

export const actions = {
  // @todo
}
