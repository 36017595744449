//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DefaultLayout',
  data: () => ({ currentSection: 0 }),
  mounted() {
    this.init()
  },

  methods: {
    init() {
      const gsap = this.$gsap
      const ScrollTrigger = this.$ScrollTrigger
      gsap.utils.toArray('header, .works, .about, .contact').forEach((item, i) => {
        const start = 'top 45%'
        const end = 'bottom 45%'

        ScrollTrigger.create({
          trigger: item,
          start,
          end,
          onEnter: () => (this.currentSection = i),
          onEnterBack: () => (this.currentSection = i),
        })
      })

      this.setViewHeight()
      window.addEventListener('resize', this.setViewHeight)
    },
    setViewHeight() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
  },
}
